import React from "react"
import { graphql, PageProps } from "gatsby"
import { Query } from "@graphql-types"
import { SEO, Blocks } from "@global"

interface Props extends PageProps {
  data: Query
}

const PageTemplate = ({ data }: Props) => {
  if (data.sanityPage == null) return null

  const { seo, pageContent, title } = data.sanityPage

  return (
    <div className="subpage-container">
      <SEO data={seo} />
      {pageContent && <Blocks data={pageContent} pageTitle={title} />}
    </div>
  )
}

export const query = graphql`
  query pagesTemplateQuery($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      seo {
        ...sanitySeo
      }
      title
      slug {
        current
      }
      pageContent {
        ...sanityPageContent
      }
    }
  }
`

export default PageTemplate
